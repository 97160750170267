import { DOMAIN } from "@/global/constants";
import type { Props } from "./route";

export async function metaConversion(props: Props) {
  try {
    return await fetch(`${DOMAIN}/api/conversion/meta`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(props),
    });
  } catch {
    console.error('Failed to send conversion event');
  }
}
