/**
 * Global declaration of themeColor in HEX format.
 * @constant
 */
export const THEME_COLOR: string = '#FFF6F9';

/**
 * Global declaration of backgroundColor in HEX format.
 * @constant
 */
export const BACKGROUND_COLOR: string = '#FFFDFD';

/**
 * Global declaration of page language.
 * @constant
 */
export const LOCALE: string = 'de';

/**
 * Global declaration of domain name of the website. Be aware of the protocol and www or non-www prefix.
 * @constant
 */
export const DOMAIN: string = 'https://fitpaty.de';

/**
 * Global default title.
 * @constant
 */
export const DEFAULT_TITLE: string = 'FitPaty';

/**
 * Global description.
 * @constant
 */
export const DEFAULT_DESCRIPTION: string = 'Die Freude am Essen kann mit der Sorge um die Gesundheit Hand in Hand gehen!';

/**
 * URL for the main icon.
 * @constant
 */
export const ICON_URL: string = `${DOMAIN}/fit-paty-icon.png`;

/**
 * URL for the main logo.
 * @constant
 */
export const LOGO_URL: string = `${DOMAIN}/fit-paty-logo.png`;

/**
 * Global declaration of regex.
 * @constant
 */
export const REGEX: { email: RegExp; phone: RegExp; string: RegExp } = {
  email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  phone: /^(?:\+(?:\d{1,3}))?(?:[ -]?\(?\d{1,4}\)?[ -]?\d{1,5}[ -]?\d{1,5}[ -]?\d{1,6})$/,
  string: /^(?!\s+$)(.*?)\s*$/,
};

/**
 * Declaration of global easing.
 * @constant
 */
export const EASING: number[] = [0.6, -0.15, 0.27, 1.15];

/**
 * Legal links.
 * @constant
 */
export const LEGAL_LINKS = {
  privacyPolicy: `/legal/datenschutzerklarung.pdf`,
  termsAndConditions: `/legal/agb.pdf`,
  cancellationPolicy: `/legal/widerrufsbelehrung.pdf`,
  legalNotice: `/legal/impressum.pdf`,
};

/**
 * Global email address.
 * @constant
 */
export const GLOBAL_EMAIL = 'contact@fitpaty.de';

/**
 * Sending domain from Resend.
 * @constant
 */
export const SENDING_DOMAIN = 'send.fitpaty.de';
