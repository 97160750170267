'use client';
import Link from 'next/link';
import useEmblaCarousel from 'embla-carousel-react';
import { usePagination, useNavigation } from '@/components/global/Carousel';
import '@/components/global/Carousel/embla.scss';
import styles from './Reviews.module.scss';
import ReviewScore from '@/components/ui/ReviewScore';
import type { SliderTypes } from './Reviews.types';

export default function Slider({ list, QuoteIcon, LeftArrowIcon, RightArrowIcon, PaginationIcon }: SliderTypes) {
  const [emblaRef, emblaApi] = useEmblaCarousel({ align: 'start', skipSnaps: true, loop: true });
  const { scrollSnaps, selectedIndex, onDotButtonClick } = usePagination(emblaApi);
  const { prevBtnDisabled, nextBtnDisabled, onPrevButtonClick, onNextButtonClick } = useNavigation(emblaApi);

  return (
    <>
      <div className={`embla ${styles['Slider']}`} ref={emblaRef}>
        <div className="embla__container">
          {list?.map(({ name, rating, productName, productSlug, content }, i) => (
            <div className={`embla__slide ${styles.slide}`} key={i}>
              {QuoteIcon}
              <header>
                <h3>{name}</h3>
                <ReviewScore rating={rating} />
              </header>
              <p className={styles.purchased}><span>Gekauft von:</span> <Link href={`/shop/${productSlug}`}>{productName}</Link></p>
              {content}
            </div>
          ))}
        </div>
      </div>
      {list.length > 1 && (
        <div className={styles['Pagination']}>
          <>
            <button
              type='button'
              onClick={onPrevButtonClick}
              disabled={prevBtnDisabled}
              aria-label='Gehe zum vorherigen Element'
            >
              {LeftArrowIcon}
            </button>
            <div className={styles.dots}>
              {scrollSnaps.map((_, index) => (
                <button
                  key={index}
                  type='button'
                  onClick={() => onDotButtonClick(index)}
                  aria-current={index === selectedIndex}
                  aria-label={`Gehe zum ${index + 1}. Element`}
                >
                  {PaginationIcon}
                </button>
              ))}
            </div>
            <button
              type='button'
              onClick={onNextButtonClick}
              disabled={nextBtnDisabled}
              aria-label='Gehe zum nächsten Element'
            >
              {RightArrowIcon}
            </button>
          </>
        </div>
      )}
    </>
  );
}
