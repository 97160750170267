'use client';
import Button from '@/components/ui/Button';
import type { BuyButtonTypes } from './BuyButton.types';
import { metaConversion } from '@/app/api/conversion/meta';
import { tiktokConversion } from '@/app/api/conversion/tiktok';

export default function BuyButton({ children, href, item_id, item_name, item_price, ...props }: BuyButtonTypes) {
  const contents = [{
    id: item_id,
    item_name: item_name,
    item_price: item_price,
  }];

  const handleClick = async () => {
    const event_id = crypto.randomUUID();
    metaConversion({
      event_id: event_id,
      event_name: 'AddToCart',
      contents: contents,
      content_name: item_name,
      content_price: item_price,
      event_source_url: window.location.href,
    })
    tiktokConversion({
      event_id: event_id,
      event_name: 'AddToCart',
      contents: contents,
      content_name: item_name,
      content_price: item_price,
      event_source_url: window.location.href,
    });

    gtag('event', 'add_to_cart', {
      event_id: event_id,
      value: item_price,
      currency: 'EUR',
      items: [
        {
          item_id: item_id,
          item_name: item_name,
          item_brand: "FitPaty",
          price: item_price,
          quantity: 1
        }
      ],
    });
    gtag('event', 'begin_checkout', {
      event_id: event_id,
      value: item_price,
      currency: 'EUR',
      items: [
        {
          item_id: item_id,
          item_name: item_name,
          item_brand: "FitPaty",
          price: item_price,
          quantity: 1
        }
      ],
    });
    if (typeof fbq !== 'undefined') {
      fbq('track', 'AddToCart', {
        content_ids: [item_id],
        content_name: item_name,
        content_type: 'product',
        value: item_price,
        currency: 'EUR',
      }, { eventID: event_id });
    }
    if (typeof ttq !== 'undefined') {
      ttq.track('AddToCart', {
        "contents": [
          {
            "content_id": item_id,
            "content_type": 'product',
            "content_name": item_name,
          }
        ],
        "value": item_price,
        "currency": 'EUR',
      }, { event_id: event_id });
    }
  };

  return (
    <Button
      href={href}
      onClick={handleClick}
      {...props}
    >{children}</Button>
  );
}
